"use client";
import { Alert, Button, Divider, Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { authenticate } from "@/store/slices/auth";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { useState } from "react";
import { useRouter } from "next/navigation";

export default function Login() {
  const dispatch = useDispatch<AppDispatch>();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const router = useRouter();
  const { isLoading, errors } = useSelector((state: RootState) => state.auth);
  return (
    <div className="no-bottom no-top" id="content">
      <section
        id="section-hero"
        aria-label="section"
        className="jarallax"
        style={{ backgroundImage: "url(/images/background/2.jpg)" }}
      >
        <div className="v-center">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 offset-lg-4">
                <div
                  className="padding40 rounded-3 shadow-soft"
                  data-bgcolor="#ffffff"
                >
                  <h4 className="mb-0">Admin auth</h4>
                  <p
                    style={{ color: "#ccc", lineHeight: 1, fontSize: 14 }}
                    className="mt-1 mb-0 pb-0"
                  >
                    Super secured admin dashboard.
                  </p>
                  <Divider className="mt-3" />

                  {errors && errors.message && (
                    <>
                      <Alert
                        message="Failed"
                        description={errors.message}
                        type="error"
                        showIcon
                      />
                      <br />
                    </>
                  )}

                  <form
                    id="form_register"
                    className="form-border"
                    method="post"
                    action="account-dashboard.html"
                  >
                    <div className="field-set mb-3">
                      <Input
                        value={email}
                        onChange={({ target: { value } }) => {
                          setEmail(value);
                        }}
                        id="username"
                        placeholder="user@example.com"
                      />
                    </div>
                    <div className="field-set mt-3">
                      <Input.Password
                        value={password}
                        onChange={({ target: { value } }) => {
                          setPassword(value);
                        }}
                        placeholder="input password"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </div>
                    <Divider />
                    <div className="mt-1" id="submit">
                      <Button
                        onClick={async () => {
                          const { payload }: { payload: any } = await dispatch(
                            authenticate({
                              email: email,
                              auth_password: password,
                            }),
                          );
                          if (
                            payload &&
                            payload.status &&
                            payload.status === 200
                          ) {
                            router.push("/dashboard/reservations");
                          }
                        }}
                        loading={isLoading}
                        size="large"
                        block
                        type="primary"
                      >
                        Log In
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
